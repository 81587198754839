import React from 'react';
import "./src/style.scss";
import icon from './src/icon.jpg';
import SettingsIcon from './src/settings-icon.js';

const SCROLL_ANIMATION_FINISHED = 200;
//const SCROLL_ANIMATION_DEFAULT = 0;

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.createHeaderState(0); //set default values

        this.intervalID = 0;
        this.intervalRunning = false;

        this.mainHeaderContentTextFadeID = -1;
        this.mainHeaderContentTextFadeTriggerID = -1;

        this.scrolling = this.scrolling.bind(this);
        this.expandOptionBar = this.expandOptionBar.bind(this);
        this.animateOptionBar = this.animateOptionBar.bind(this);

        this.mainHeaderContentTextFade = this.mainHeaderContentTextFade.bind(this);
        this.triggerFadeAnimation = this.triggerFadeAnimation.bind(this);
        this.mainHeaderContentTextFadeAnimation = this.mainHeaderContentTextFadeAnimation.bind(this);
    }

    render() {
        document.documentElement.style.setProperty('--scroll-bar-width', window.innerWidth - document.documentElement.clientWidth + "px");
        document.documentElement.style.setProperty('--primary-header-color', this.state.contentColor);

        return (
            <div className={"header"}>
                <div id={"mainHeader"}>
                    <div id={"mainHeaderBackground"} style={{
                        opacity: this.state.opacity
                    }}/>
                    <div id={"mainHeaderContent"}>
                        <div id={"mainHeaderContentSettings"}>
                            <div id={"mainHeaderContentSettingsWrapper"} onClick={this.expandOptionBar}>
                                <div id={"mainHeaderContentSettingsBackground"}/>
                                <SettingsIcon color={this.state.contentColor}/>
                            </div>
                        </div>
                        <div id={"mainHeaderContentText"}
                             style={{
                                 opacity: this.state.mainHeaderContentTextOpacity/100
                             }}
                        >
                            <div className={"mainHeaderContentTextPart"}>ARCHITEKTUR</div>
                            <div className={"mainHeaderContentTextPart"}>PLANEN</div>
                            <div className={"mainHeaderContentTextPart"}>BAUEN</div>
                        </div>
                    </div>
                </div>

                <div id={"headerImage"} style={{
                    marginTop: this.state.defaultIconTopMargin,
                    marginRight: this.state.defaultIconRightMargin
                }}>
                    <img id={"headerImageContent"} src={icon} onClick={this.onLogoClick} alt={""}/>
                </div>

                <div id={"optionHeader"} style={{
                    marginTop: this.state.optionBarMargin / 10 + "vh", // see below for more information
                    visibility: this.state.optionBarVisible ? "visible" : "hidden"
                }}>
                    <div id={"optionHeaderBackground"} style={{
                        opacity: this.state.opacity
                    }}/>
                    <div id={"optionHeaderContent"}>
                        <a className={"optionHeaderContentText"} href={"/home"}>Home</a>
                        <a className={"optionHeaderContentText"} href={"/buero"}>Büro</a>
                        <a className={"optionHeaderContentText"} href={"/kontakt"}>Kontakt</a>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        document.querySelector('.container').addEventListener('scroll', this.scrolling);
    }

    componentWillUnmount() {
        document.querySelector('.container').removeEventListener('scroll', this.scrolling);
    }

    scrolling() {
        this.setState(this.createHeaderState(document.querySelector('.container').scrollTop));
        this.mainHeaderContentTextFade();
    }

    createHeaderState(scrollLevel) {
        // 0 = default
        // SCROLL_ANIMATION_FINISHED = animation complete
        if (scrollLevel > SCROLL_ANIMATION_FINISHED)
            scrollLevel = SCROLL_ANIMATION_FINISHED;

        return {
            defaultIconRightMargin: "calc(0.5*" + this.relativize(scrollLevel, 4, true) + "vw" + // use vh to avoid scrolling bug
                " + var(--scroll-bar-width)", // use this subtraction to ignore the scrollbar
            defaultIconTopMargin: this.relativize(scrollLevel, 4, true) + "vh",
            opacity: this.relativize(scrollLevel, 1, true),
            contentColor: "rgb(" + this.relativize(scrollLevel, 255, true) + ", " +
                this.relativize(scrollLevel, 255, true) + ", " +
                this.relativize(scrollLevel, 255, true) + ")",
            optionBarMargin: this.state == null || this.state.optionBarMargin == null ? 30 : this.state.optionBarMargin, //hides behind main header
            optionBarVisible: this.state == null || this.state.optionBarVisible == null ? false : this.state.optionBarVisible,
            mainHeaderContentTextOpacity: this.state == null || this.state.mainHeaderContentTextOpacity == null ? 100 : this.state.mainHeaderContentTextOpacity,
        };
    }

    relativize(level, newLimit, invert = false) {
        return (invert ? SCROLL_ANIMATION_FINISHED - level : level) / SCROLL_ANIMATION_FINISHED * newLimit;
    }

    expandOptionBar() {
        if (!this.intervalRunning) {
            // assumes that option bar margin is set to 50 if out=true, otherwise 100

            this.intervalID = window.setInterval(this.animateOptionBar, 10, !this.state.optionBarVisible);
            this.intervalRunning = true;

            this.setState({optionBarVisible: true});
        }

        this.mainHeaderContentTextFade(); //display labels when option bar is activated
    }

    animateOptionBar(out = true) {
        // do not use floating points, as they store the value incorrectly
        if ((this.state.optionBarMargin >= 100 && out) ||
            (this.state.optionBarMargin <= 30 && !out)) {
            this.intervalRunning = false;

            if (!out) {
                this.setState({optionBarVisible: false});
                this.mainHeaderContentTextFade(); //let labels disappear when option bar is away
            }

            clearInterval(this.intervalID);
        } else
            this.setState({
                optionBarMargin: this.state.optionBarMargin +
                    (out ? +5 : -5)
            })
    }

    onLogoClick() {
        document.location.href = "/";
    }

    mainHeaderContentTextFade() {
        this.setState({
            mainHeaderContentTextOpacity: 100
        });
        window.clearInterval(this.mainHeaderContentTextFadeID);
        this.mainHeaderContentTextFadeID = -1;
        window.clearTimeout(this.mainHeaderContentTextFadeTriggerID);
        this.mainHeaderContentTextFadeTriggerID = -1;

        if (this.mainHeaderContentTextFadeID < 0 && this.state.opacity === 0 && !this.state.optionBarVisible)
            this.mainHeaderContentTextFadeID = window.setTimeout(this.triggerFadeAnimation, 750);
    }

    triggerFadeAnimation() {
        this.mainHeaderContentTextFadeTriggerID = -1;
        this.mainHeaderContentTextFadeID = window.setInterval(this.mainHeaderContentTextFadeAnimation, 10);
    }

    mainHeaderContentTextFadeAnimation() {
        if (this.state.mainHeaderContentTextOpacity <= 0) {
            window.clearInterval(this.mainHeaderContentTextFadeID);
            this.mainHeaderContentTextFadeID = -1;
        } else {
            this.setState({
                mainHeaderContentTextOpacity: this.state.mainHeaderContentTextOpacity - 1
            });
        }
    }
}