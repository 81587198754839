import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import './src/style.scss';

import NotFound from "./sites/notfound/main";

import Projects from './sites/projects/main';

import Home from './sites/home/main';
import Office from './sites/office/main';
import Contact from './sites/contact/main';

import Imprint from "./sites/imprint/main";
import PrivacyPolicy from "./sites/privacypolicy/main";

export default class Content extends React.Component {
    render() {
        return (
            <div className={"content"}>

                <Router>
                    <Switch>
                        <Route path={[
                            '/home', '/', '/heim', '/zuhause', '/athome',
                            '/houm', '/hom', '/haum', '/haupseite', '/hauptseite',
                            '/hone', '/haupt', '/haup', '/zurück', '/start', '/haus', '/h'
                        ]} exact render={() => {
                            window.history.pushState("", "", "/home");
                            return (<div className={"page-wrapper"}>
                                <Projects/>
                                <div className={"page-content"}>
                                    <Home/>
                                </div>
                            </div>);
                        }}/>

                        <Route path={[
                            '/office', '/offiz', '/offic', '/ofice', '/ofic', '/buero', '/büro',
                            '/buro', '/burak', '/o', '/b'
                        ]} exact render={() => {
                            window.history.pushState("", "", "/buero");
                            return (<div className={"page-wrapper"}>
                                <Projects/>
                                <div className={"page-content"}>
                                    <Office/>
                                </div>
                            </div>);
                        }}/>

                        <Route path={[
                            '/kontakt', '/contact', '/kontact', '/contakt', '/konakt', '/kontaktieren', '/anfahrt',
                            '/hinfahrt', '/telefon', '/ansprechen', '/sprechen', '/email', '/e-mail', '/kommunizieren', '/k', '/c'
                        ]} exact render={() => {
                            window.history.pushState("", "", "/kontakt");
                            return (<div className={"page-wrapper"}>
                                <Projects/>
                                <div className={"page-content"}>
                                    <Contact/>
                                </div>
                            </div>);
                        }}/>

                        <Route path={[
                            '/impressum', '/imprint', '/inprint', '/inpressum', '/impressun', '/impressus', '/impresum',
                            '/information', '/recht', '/o', '/i'
                        ]} exact render={() => {
                            window.history.pushState("", "", "/impressum");
                            return <Imprint/>;
                        }}/>

                        <Route path={[
                            '/datenschutz', '/datenschutzerklaerung', '/datemschutz', '/daten', '/privatsphäre', '/privat', '/privacy', '/privacypolicy',
                            '/privacy-policy', '/daten-schutz', '/d', '/p'
                        ]} exact render={() => {
                            window.history.pushState("", "", "/datenschutz");
                            return <PrivacyPolicy/>;
                        }}/>
                        <Route component={NotFound}/>
                    </Switch>
                </Router>
            </div>
        );
    }
}