import React from 'react';
import DocumentMeta from 'react-document-meta';

import meta from './src/meta.json';

import './src/style.scss';
import qrCode from './src/qr-code.png';
import data from './src/data.json';


export default class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.parseContactFields = this.parseContactFields.bind(this);
        this.parseAddressFields = this.parseAddressFields.bind(this);

        this.contactFields = [];
        Object.keys(data["contact-field"]).forEach(this.parseContactFields);

        this.addressFields = [];
        Object.keys(data["address-field"]).forEach(this.parseAddressFields);
    }

    render() {
        return (<>
            <DocumentMeta {...meta} />

            <div className={"contact-content"}>
                <div className={"contact-segment"}>
                    <div className={"contact-upper-title"}>
                        Kontakt
                    </div>
                    {this.contactFields}
                    <div id={"qr"}>
                        <img id={"qr-content"} src={qrCode} alt={"qr"}/>
                    </div>
                </div>

                <div className={"contact-segment"}>
                    <div className={"contact-upper-title"}>
                        Adresse
                    </div>
                    <div id={"map"}>
                        <iframe id={"map-content"}
                                title={"location"}
                                frameBorder="0" scrolling="no"
                                src={"https://www.openstreetmap.org/export/embed.html?bbox=7.152145206928254%2C51.51766449630322%2C7.155578434467317%2C51.51916162490802&amp;layer=mapnik"}
                        />
                    </div>
                    {this.addressFields}
                </div>
            </div>
        </>);
    }

    parseContactFields(key, i) {
        this.contactFields.push(<div className={"contact-field"} key={`contact-field${i}`}>
            <span className={"contact-field-name"}>{key}: </span>
            <span className={"contact-field-value"}>{data["contact-field"][key]}</span>
        </div>);
    }

    parseAddressFields(key, i) {
        this.addressFields.push(<div className={"address-field"} key={`address-field${i}`}>
            <span className={"address-field-value"}>{data["address-field"][key]}</span>
        </div>);
    }
}