import React from 'react';
import './src/style.scss';

import image from './src/icon.jpg';

export default class Footer extends React.Component {
    render() {
        return (<>
            <div className={"footer"}>
                <div className={"main-footer"}>
                    <div className={"footer-image-container"}>
                        <img className={"footer-image"} src={image} alt={""}/>
                        <div className={"footer-image-description"}>Architekturbüro Drees</div>
                    </div>
                    <div className={"footer-text"}>
                        Gemacht mit &#9825; - Weitere Informationen befinden sich im <a
                        href={"/imprint"}>Impressum</a> und
                        bei <a href={"/datenschutz"}>Datenschutz</a>
                    </div>
                </div>
                <div className={"safari-addition"}/>
            </div>
        </>);

    }
}
