import React from 'react';

export default class SettingsIcon extends React.Component {
    render() {
        return (
            <svg id={"mainHeaderContentSettingsContent"} viewBox={"0 0 500 500"} xmlns="http://www.w3.org/2000/svg">
                {/* Created with Method Draw - http://github.com/duopixel/Method-Draw/ */}
                <g>
                    <title>Content</title>
                    <rect rx="50" id="svg_3" height="60" width="360" y="100" x="70" strokeWidth="0" stroke="#000" fill={this.props.color}/>
                    <rect rx="50" id="svg_4" height="60" width="360" y="200" x="70" strokeWidth="0" stroke="#000" fill={this.props.color}/>
                    <rect rx="50" id="svg_5" height="60" width="360" y="300" x="70" strokeWidth="0" stroke="#000" fill={this.props.color}/>
                </g>
            </svg>
        );
    }
}