import React from 'react';
import DocumentMeta from 'react-document-meta';

import meta from './src/meta.json';

import './src/style.scss';

import Data from './src/data';

export default class Imprint extends React.Component {
    render() {
        return (<>
            <DocumentMeta {...meta} />

            <div className={"imprint-policy"}>
                <Data />
            </div>
        </>);
    }
}