import React from 'react';

import './src/style.scss';
import leftArrow from "./src/arrow-circle-left.svg";
import rightArrow from "./src/arrow-circle-right.svg";
import leftArrowHighlight from "./src/arrow-circle-left-highlight.svg";
import rightArrowHighlight from "./src/arrow-circle-right-highlight.svg";

const BASE_PATH = './src/';
const BASE_NAME = 'proj';

class Project extends React.Component {

    constructor(props) {
        super(props);

        this.parent = props.parent;

        this.mouseDownX = 0;
        this.currentlyMouseDown = false;

        this.state = {borderStroke: 0, currentImageIndex: 0, images: [], isFocused: false};

        this.number = props.number;
        this.name = BASE_NAME + props.number;

        this.type = require(`${BASE_PATH}${this.name}/data.json`).type;
        this.year = require(`${BASE_PATH}${this.name}/data.json`).year;
        this.title = require(`${BASE_PATH}${this.name}/title.json`).title;

        this.intervalID = 0;

        this.getAttention = this.getAttention.bind(this);
        this.loseAttention = this.loseAttention.bind(this);
        this.animateBorder = this.animateBorder.bind(this);

        this.focus = this.focus.bind(this);
        this.unfocus = this.unfocus.bind(this);

        this.rightSwipe = this.rightSwipe.bind(this);
        this.leftSwipe = this.leftSwipe.bind(this);
        this.offsetImage = this.offsetImage.bind(this);

        this.focusMouseDown = this.focusMouseDown.bind(this);
        this.focusMouseUp = this.focusMouseUp.bind(this);
        this.focusMouseLeave = this.focusMouseLeave.bind(this);
        this.focusTouchStart = this.focusTouchStart.bind(this);
        this.focusTouchEnd = this.focusTouchEnd.bind(this);

        this.onKeyPressed = this.onKeyPressed.bind(this);

        this.leftFocusSideHighlight = this.leftFocusSideHighlight.bind(this);
        this.leftFocusSideUnhighlight = this.leftFocusSideUnhighlight.bind(this);
        this.rightFocusSideHighlight = this.rightFocusSideHighlight.bind(this);
        this.rightFocusSideUnhighlight = this.rightFocusSideUnhighlight.bind(this);
    }

    loadImages() {
        try {
            require(`${BASE_PATH}${this.name}/images/spoiler.jpg`); //throws exception

            import(`${BASE_PATH}${this.name}/images/spoiler.jpg`).then(image => {
                this.setState({
                    spoiler: image
                });
            });


            let i = 1;
            while (true) {
                require(`${BASE_PATH}${this.name}/images/img${i}.jpg`); //throws exception

                import(`${BASE_PATH}${this.name}/images/img${i}.jpg`).then(image => {
                    this.setState({
                        images: this.state.images.concat(image)
                    });
                });
                i++;
            }
        } catch (e) {
            //jump out of loop, reached end of images
        }
    }

    render() {
        return (
            <>
                {this.state.isFocused &&
                <>
                    <div className={"focus-background"}
                         onMouseDown={this.leftFocusSideHighlight} onTouchStart={this.leftFocusSideHighlight}
                         onMouseUp={this.leftFocusSideUnhighlight} onTouchEnd={this.leftFocusSideUnhighlight}
                         onClick={this.unfocus}
                    />
                    <div className={"focus-wrapper"}>
                        <div className={"focus-side"} onClick={this.leftSwipe}>
                            <img id={"focus-side-left-img"} className={"focus-side-content"} src={leftArrow} alt={""}/>
                        </div>
                        <div id={"focus"}
                             onMouseDown={this.focusMouseDown}
                             onMouseUp={this.focusMouseUp}
                             onMouseLeave={this.focusMouseLeave}
                             onTouchStart={this.focusTouchStart}
                             onTouchEnd={this.focusTouchEnd}
                        >
                            <img className={"focus-content"} src={
                                this.state.images[this.state.currentImageIndex] === undefined ? "" : this.state.images[this.state.currentImageIndex].default
                            } alt={this.title}/>
                            <div className={"flex-container"}>
                                <div className={"focus-information"}>
                                    <div className={"focus-title"}>
                                        {this.title}
                                    </div>
                                    <div className={"focus-data"}>
                                        <span className={"focus-type"}>{this.type}</span> aus <span
                                        className={"focus-year"}>{this.year}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"focus-side"}
                             onMouseDown={this.rightFocusSideHighlight} onTouchStart={this.rightFocusSideHighlight}
                             onMouseUp={this.rightFocusSideUnhighlight} onTouchEnd={this.rightFocusSideUnhighlight}
                             onClick={this.rightSwipe}>
                            <img id={"focus-side-right-img"} className={"focus-side-content"} src={rightArrow} alt={""}/>
                        </div>
                    </div>
                </>
                }
                <div className={"project-container"}
                     id={this.name}
                     onMouseMove={this.getAttention}
                     onMouseLeave={this.loseAttention}
                     onClick={this.focus}
                     style={{
                         borderWidth: this.state.borderStroke / 100 + "vw",
                         order: this.number
                     }}
                >
                    <img className={"project-spoiler"}
                         src={this.state.spoiler === undefined ? "" : this.state.spoiler.default}
                         alt={this.title}/>
                </div>
            </>
        );
    }

    componentDidMount() {
        this.loadImages();
        window.addEventListener('keydown', this.onKeyPressed);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyPressed);
    }


    getAttention() {
        if (this.intervalID !== 0)
            clearInterval(this.intervalID);
        this.intervalID = setInterval(this.animateBorder, 10, true);

        if (!this.isMobile())
            this.parent.setState({
                projectPreviewVisible: true,
                title: this.title
            });
    }

    loseAttention() {
        if (this.intervalID !== 0)
            clearInterval(this.intervalID);
        this.intervalID = setInterval(this.animateBorder, 10, false);

        if (!this.isMobile())
            this.parent.setState({
                projectPreviewVisible: false,
                title: this.title
            });
    }

    animateBorder(expanding = true) {
        if ((this.state.borderStroke >= 50 && expanding) ||
            (this.state.borderStroke <= 0 && !expanding)) {
            clearInterval(this.intervalID);
            this.intervalID = 0;
        } else
            this.setState({
                borderStroke: this.state.borderStroke +
                    (expanding ? +5 : -5)
            });
    }

    focus() {
        this.setState({isFocused: true});
    }

    unfocus() {
        this.setState({isFocused: false});
    }

    leftSwipe() {
        this.setState({
            currentImageIndex: this.offsetImage(false)
        });
    }

    rightSwipe() {
        this.setState({
            currentImageIndex: this.offsetImage(true)
        });
    }

    offsetImage(forward) {
        if (forward)
            return this.state.currentImageIndex === this.state.images.length - 1 ? 0 : this.state.currentImageIndex + 1;
        else
            return this.state.currentImageIndex === 0 ? this.state.images.length - 1 : this.state.currentImageIndex - 1;
    }

    focusMouseDown(e) {
        this.currentlyMouseDown = true;
        this.mouseDownX = e.pageX;

        e.preventDefault();
    }

    focusMouseUp() {
        this.currentlyMouseDown = false;
    }

    focusMouseLeave(e) {
        if (this.currentlyMouseDown) {
            this.currentlyMouseDown = false;

            if (e.pageX > this.mouseDownX)
                this.leftSwipe();
            else
                this.rightSwipe();
        }

        e.preventDefault();
    }

    focusTouchStart(e) {
        const evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
        const touch = evt.touches[0] || evt.changedTouches[0];

        this.currentlyMouseDown = true;
        this.mouseDownX = touch.pageX;

        e.preventDefault();
    }

    focusTouchEnd(e) {
        const evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
        const touch = evt.touches[0] || evt.changedTouches[0];

        const focus = document.getElementById('focus');

        if (this.currentlyMouseDown) {
            this.currentlyMouseDown = false;

            if (touch.pageX > this.mouseDownX && touch.pageX > focus.offsetLeft + focus.clientWidth)
                this.leftSwipe();
            else if (touch.pageX < this.mouseDownX && touch.pageX < focus.offsetLeft)
                this.rightSwipe();
        }

        e.preventDefault();

        this.currentlyMouseDown = false;
    }

    onKeyPressed(e) {
        if (this.state.isFocused)
            if (e.keyCode === 37) //LEFT
                this.leftSwipe();
            else if (e.keyCode === 39) //RIGHT
                this.rightSwipe();
    }

    isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
    }

    leftFocusSideHighlight() {
        document.getElementById("focus-side-left-img").src = leftArrowHighlight;
    }

    leftFocusSideUnhighlight() {
        document.getElementById("focus-side-left-img").src = leftArrow;
    }

    rightFocusSideHighlight() {
        document.getElementById("focus-side-right-img").src = rightArrowHighlight;
    }

    rightFocusSideUnhighlight() {
        document.getElementById("focus-side-right-img").src = rightArrow;
    }
}

export default class Projects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectPreviewVisible: false,
            top: "0px",
            left: "0px",
            title: ""
        }

        this.mouseMove = this.mouseMove.bind(this);
    }


    render() {
        let projects = [];

        for (let i = 1; ; i += 2) {
            if (!this.checkProjectName(`${BASE_NAME}${i}`))
                break;
            projects.push(<Project parent={this} number={i} key={i}/>);
            if (!this.checkProjectName(`${BASE_NAME}${i + 1}`))
                break;
            projects.push(<Project parent={this} number={i + 1} key={i + 1}/>);
        }

        return (
            <div className={"projects-container"}>
                <div className={"project-preview"}
                     style={{
                         visibility: this.state.projectPreviewVisible ? "visible" : "hidden",
                         top: this.state.mousePageY,
                         left: this.state.mousePageX
                     }}
                >
                    {this.state.title}
                </div>
                {projects}
            </div>
        )
    }

    componentDidMount() {
        document.addEventListener("mousemove", this.mouseMove);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.mouseMove);
    }

    checkProjectName(projectName) {
        try {
            require(`${BASE_PATH}${projectName}/data.json`);
            return true;
        } catch (e) {
            return false;
        }
    }

    mouseMove(e) {
        this.setState({
            mousePageX: e.pageX,
            mousePageY: e.pageY
        });
    }
}