import React from 'react';

import './src/style.scss';
import Data from './src/data';

export default class PrivacyPolicy extends React.Component {
    render() {
        return (<>
            <div className={"privacy-policy-container"}>
                <Data/>
            </div>
        </>);
    }
}