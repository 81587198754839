import React from 'react';
import DocumentMeta from 'react-document-meta';

import meta from './src/meta.json';
import './src/style.scss';
import data from './src/data.json';


export default class Home extends React.Component {
    constructor(props) {
        super(props);

        this.upperTitle = data.upper_title;
        this.lowerTitle = data.lower_title;
        this.fields = [];


        this.parseFields = this.parseFields.bind(this);

        Object.keys(data.contents).forEach(this.parseFields);
    }

    render() {
        return (<>
            <DocumentMeta {...meta} />

            <div className={"home-content"}>
                <div className={"home-upper-title"}>{this.upperTitle}</div>
                <div className={"home-information"}>
                    {this.fields}
                    <div className={"home-lower-title"}>{this.lowerTitle}</div>
                </div>
            </div>
        </>);
    }

    parseFields(key, i) {
        this.fields.push(<div className={"field"} key={`field${i}`}>
            <div className={"field-title"}
                 onMouseEnter={this.onMouseEnter}
                 onMouseLeave={this.onMouseLeave}
            >
                {key}
            </div>
            <div className={"field-value"}>
                {data.contents[key]}
            </div>
        </div>);
    }

    onMouseEnter(e) {
        e.target.style.color = "rgb(200, 100, 100)";
    }

    onMouseLeave(e) {
        e.target.style.color = "rgb(0, 0, 0)";
    }
}