import React from 'react';
import './src/style.scss';

import TopButton from "./top-button/TopButton";


export default class WindowApps extends React.Component {
    render() {
        return (
            <div className={"window-app-container"}>
                <TopButton />
            </div>
        )
    }
}