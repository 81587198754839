import React from 'react';
import DocumentMeta from 'react-document-meta';

import meta from './src/meta.json';

import './src/style.scss';


export default class NotFound extends React.Component {
    constructor(props) {
        super(props);

        this.state = {timeLeft: 15}
        this.countDown = this.countDown.bind(this);
    }

    render() {
        return (<>
            <DocumentMeta {...meta} />

            <div className={"not-found-wrapper"}>
                <div className={"not-found-title"}>
                    404 Nicht gefunden
                </div>
                <div className={"not-found-text"}>
                    Die von Ihnen aufgerufene Seite scheint nicht zu existieren. Sie werden in {this.state.timeLeft}&nbsp;
                    Sekunden zurück auf die Startseite geleitet <br />
                    <a href={"/"}>Jetzt weiterleiten</a>
                </div>
            </div>
        </>);
    }

    componentDidMount() {
        window.setInterval(this.countDown, 1000);
    }

    countDown() {
        this.setState({timeLeft: this.state.timeLeft - 1});

        if (this.state.timeLeft <= 0)
            window.location.href = "/";
    }
}