import React from 'react';
import DocumentMeta from 'react-document-meta';

import Header from './header/main';
import Footer from './footer/main';
import WindowApps from "./window/main";

import Content from "./content/main";

import './src/style.scss';

import meta from './src/meta.json';


export default class Construct extends React.Component {
    render() {
        return (
          <div className="container">
              <DocumentMeta {...meta}/>
              <WindowApps />
              <Header />
              <Content />
              <Footer />
          </div>
        );
    }
}