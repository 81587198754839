import React from 'react';

import './src/style.scss';
import DocumentMeta from 'react-document-meta';

import meta from './src/meta.json';
import data from "./src/data.json";

export default class Office extends React.Component {
    constructor(props) {
        super(props);

        this.data = data;

        this.upperTitle = this.data.upper_title;

        this.fields = [];

        for (let i = 0; i < this.data.employees.length; i++) {
            let imageSrc = "";

            try {
                imageSrc = require(`${this.data.employees[i].image_path}`) // marks are important
            } catch (e) {
                console.log(e);
            }

            this.fields.push(
                <div className={"employee-entry"} key={`employee${i}`}>
                    <img className={"employee-image"} src={imageSrc} alt={this.data.employees[i].name}/>
                    <div className={"employee-information"}>
                        <div className={"employee-name"}>{this.data.employees[i].name}</div>
                        <div className={"employee-job"}>{this.data.employees[i].job}</div>
                    </div>
                </div>
            );
        }
    }

    render() {
        return (<>
            <DocumentMeta {...meta} />

            <div className={"office-content"}>
                <div className={"office-upper-title"}>DAS BÜRO</div>
                {this.fields}
            </div>
        </>);
    }
}