import React from 'react';

import './src/style.scss';

import icon from './src/icon.svg';

const SCROLL_ANIMATION_FINISHED = 200;
//const SCROLL_ANIMATION_DEFAULT = 0;

export default class TopButton extends React.Component {
    constructor(props) {
        super(props);

        this.fadeIntervalID = 0;
        this.scrollIntervalID = 0;

        this.visible = false;

        this.state = {
            opacity: 0
        };


        this.scrolling = this.scrolling.bind(this);
        this.animate = this.animate.bind(this);

        this.scrollUp = this.scrollUp.bind(this);
        this.animateScrollUp = this.animateScrollUp.bind(this);
    }

    render() {
        return (
            <div id={"top-button"} style={{
                opacity: this.state.opacity/100
            }}>
                <img id={"top-button-link"} onClick={this.scrollUp} src={icon} width={800} height={600} alt={""}/>
            </div>
        );
    }

    componentDidMount() {
        document.querySelector('.container').addEventListener('scroll', this.scrolling);
    }

    componentWillUnmount() {
        document.querySelector('.container').addEventListener('scroll', this.scrolling);
    }

    scrolling() {
        if (document.querySelector('.container').scrollTop > SCROLL_ANIMATION_FINISHED && !this.visible) {
            if (this.fadeIntervalID !== 0)
                clearInterval(this.fadeIntervalID);

            this.visible = true;
            this.fadeIntervalID = window.setInterval(this.animate, 20, true);
        } else if (document.querySelector('.container').scrollTop < SCROLL_ANIMATION_FINISHED && this.visible){
            if (this.fadeIntervalID !== 0)
                clearInterval(this.fadeIntervalID);

            this.visible = false;
            this.fadeIntervalID = window.setInterval(this.animate, 20, false);
        }
    }

    animate(toVisible=true) {
        if ( (this.state.opacity >= 80 && toVisible) || (this.state.opacity <= 0 && !toVisible)) {

            clearInterval(this.fadeIntervalID);
            this.fadeIntervalID = 0;
            return;
        }
        this.setState({
            opacity: this.state.opacity + (toVisible ? 1 : -1)
        });
    }

    relativize(level, newLimit, invert = false) {
        return (invert ? SCROLL_ANIMATION_FINISHED - level : level) / SCROLL_ANIMATION_FINISHED * newLimit;
    }

    scrollUp() {
        this.scrollIntervalID = setInterval(this.animateScrollUp, 1);
    }

    animateScrollUp() {
        if (document.querySelector('.container').scrollTop <= 0) {
            clearInterval(this.scrollIntervalID);
            this.scrollIntervalID = 0;
        }

        document.querySelector('.container').scrollTo(0, document.querySelector('.container').scrollTop-20);
    }
}